export const toQueryParams = (params) => {
  const queryParams = Object.entries(params).reduce((acc, obj, index) => {
    const [key, value] = obj
    if (typeof value === 'undefined') {
      return acc
    }
    if (typeof value === 'object') {
      return `${acc}${index === 0 ? '' : '&'}${key}=${value?.toString()}`
    }
    return `${acc}${index === 0 ? '' : '&'}${key}=${value}`
  }, '')

  return queryParams
}

export const getMonthName = (month: number) => {
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  return monthName[month - 1]
}

export const getMonthFullName = (month: number) => {
  const monthName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return monthName[month - 1]
}

export const getModelColor = (model: string) => {
  switch (model) {
    case 'ibiza':
      return '#D40028'
    case 'arona':
      return '#3AD4A7'
    case 'leon':
      return '#22283B'
    case 'ateca':
      return '#1F422C'
    case 'tarraco':
      return '#DED1A7'
    default:
      return '#000'
  }
}

import Ibiza from '../views/Dashboard/assets/ibizaw.png'
import Arona from '../views/Dashboard/assets/aronab.png'
import Leon from '../views/Dashboard/assets/leonb.png'
import Ateca from '../views/Dashboard/assets/atecab.png'
import Tarraco from '../views/Dashboard/assets/tarracob.png'

export const getModelImage = (model: string) => {
  switch (model) {
    case 'ibiza':
      return Ibiza
    case 'arona':
      return Arona
    case 'leon':
      return Leon
    case 'ateca':
      return Ateca
    case 'tarraco':
      return Tarraco
    default:
      return Ibiza
  }
}
