import { Spinner } from 'components/Spinner'
import { PaginatedTable } from 'components/Table'
import { useDemandForecastTableData } from '../../hooks/useDemandForecast'

const DemandForecastTable = (data: any) => {
  const tableDataConfig = useDemandForecastTableData(data.data)

  if (!data) {
    return <Spinner scope="local" />
  }
  if (tableDataConfig)
    return (
      <PaginatedTable
        {...tableDataConfig}
        hasScroll
        enableResultsPerPage={false}
        isEditMode={data.isEditMode}
        totalBold
        indexEditRow={data.indexEditRow}
        handleUpdateInputValue={data.handleUpdateInputValue}
      />
    )

  return <></>
}

export { DemandForecastTable }
