import { api } from 'api/api'

export const getDemandModelsFilterList = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-real-time-models',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getDemandMTFsFilterList = async (
  uuid: string,
  queryParams: string
) => {
  const response = await api.get(
    '/optimization/demand-forecast-real-time-mtfs',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getDemandByModel = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/optimization/demand-forecast-real-time-by-model',
    queryParams,
    uuid,
    2
  )
  return response
}

export const getDemandByMTF = async (uuid: string, queryParams: string) => {
  const response = await api.get(
    '/optimization/demand-forecast-real-time-by-mtf',
    queryParams,
    uuid,
    2
  )
  return response
}

export const postDemandForecastMyMtf = async (uuid: string, body: object) => {
  const response = await api.post(
    '/optimization/demand-forecast-real-time-by-mtf/training-data',
    '',
    uuid,
    2,
    body
  )
  return response
}

export const postDemandForecastMyModel = async (uuid: string, body: object) => {
  const response = await api.post(
    '/optimization/demand-forecast-real-time-by-model/training-data',
    '',
    uuid,
    2,
    body
  )
  return response
}
