import { api } from 'api/api'

export const getPrivateMarketByModel = async (uuid: string, model: string) => {
  const response = await api.get(
    '/dashboard-kpis/private-market-by-model',
    `model=${model}`,
    uuid,
    1
  )
  return response
}

export const getIncomingOrdersByModel = async (uuid: string, model: string) => {
  const response = await api.get(
    '/dashboard-kpis/incoming-orders',
    `model=${model}`,
    uuid,
    1
  )
  return response
}

export const getIncomingOrdersObjectiveByModel = async (
  uuid: string,
  model: string
) => {
  const response = await api.get(
    '/dashboard-kpis/incoming-orders-objective',
    `model=${model}`,
    uuid,
    1
  )
  return response
}

export const getInvoicedStockByModel = async (uuid: string, model: string) => {
  const response = await api.get(
    '/dashboard-kpis/invoiced-stock',
    `model=${model}`,
    uuid,
    1
  )
  return response
}

export const getLogisticStockByModel = async (uuid: string, model: string) => {
  const response = await api.get(
    '/dashboard-kpis/logistic-stock',
    `model=${model}`,
    uuid,
    1
  )
  return response
}

export const getProductionByModel = async (uuid: string, model: string) => {
  const response = await api.get(
    '/dashboard-kpis/production',
    `model=${model}`,
    uuid,
    1
  )
  return response
}

export const getBackOrdersByModel = async (uuid: string, model: string) => {
  const response = await api.get(
    '/dashboard-kpis/back-orders',
    `model=${model}`,
    uuid,
    1
  )
  return response
}
