/* eslint-disable @typescript-eslint/no-unused-vars */
import { BarChart, Bar, YAxis, LabelList, ResponsiveContainer } from 'recharts'
import { BarChartTypes } from './BarChartTypes'
import { getBadgeColorPercentaje } from 'utils'

const COLORS = ['#E18D46', '#F8E5D4', '#E18D46', '#A45FCE']

const BarChartComponent = (props: BarChartTypes) => {
  const { dataLegend, hideLegend, barColors, barHeight, barWidth } = props

  const renderCustomizedLabel = (props: any) => {
    const { x, y, height, value } = props

    const backOrdersValue = value[0]
    const description = value[1]
    const month = value[2]

    return (
      <g>
        <text
          x={x + 10}
          y={height + y - 40}
          fill={'#000'}
          textAnchor="start"
          fontSize="18"
          fontWeight="bold"
        >
          {backOrdersValue}
        </text>

        <text
          x={x + 10}
          y={height + y - 25}
          fill={'#000'}
          textAnchor="start"
          fontSize="12"
        >
          {description}
        </text>

        <text
          x={x + 10}
          y={height + y - 10}
          fill={'#000'}
          textAnchor="start"
          fontSize="12"
          fontWeight="bold"
        >
          {month}
        </text>
      </g>
    )
  }

  const customizedLabel = (props: any) => {
    const { x, y, value } = props
    const padding = 5
    const textWidth = value !== null ? value?.toString().length * 8 : 0
    const textHeight = 16

    return (
      <g>
        <rect
          x={x + 15}
          y={y - textHeight - 40}
          width={textWidth !== 0 ? textWidth + padding * 2 : 0}
          height={textHeight + padding}
          fill={getBadgeColorPercentaje(value)}
          rx={4}
          ry={4}
        />
        <text
          x={x + 20}
          y={y - 42}
          fill={
            value > 2 || value < -1 || (value > 1.3 && value < 1.7)
              ? '#fff'
              : '#000'
          }
          textAnchor="start"
          fontSize="12"
          fontWeight="normal"
        >
          {value ? `${value}%` : ''}
        </text>
      </g>
    )
  }

  const dataWithColors = dataLegend.map((entry, index) => ({
    ...entry,
    fill: barColors
      ? barColors[index % barColors.length]
      : index === 0 || index === 2
      ? COLORS[0]
      : COLORS[index % COLORS.length]
  }))

  return (
    <BarChart
      barCategoryGap={0}
      data={dataWithColors}
      margin={{ top: 60, right: 0, left: -50, bottom: 0 }}
      height={barHeight ?? 330}
      width={barWidth ?? 400}
    >
      <YAxis style={{ display: 'none' }} />
      <Bar dataKey="back_orders" isAnimationActive={false}>
        <LabelList dataKey="legend" content={renderCustomizedLabel} />
        {!hideLegend && (
          <>
            <LabelList
              dataKey="deviation_with_current_month_back_orders"
              content={customizedLabel}
            />
          </>
        )}
      </Bar>
    </BarChart>
  )
}

export { BarChartComponent as BarChartDouble }
